import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import FrontPageBanner from '../components/FrontPageBanner/FrontPageBanner'
import { SearchBar } from '../components/SearchBar'
import { Category } from '../components/Category'
import { observer } from 'mobx-react-lite'
import { useMst } from '../models/Root'

const SearchResultsPage = observer((props: any) => {

  const { search } = useMst();

  console.log(props);
  const searchResults = search.searchResults;
  console.log('This is our results: ', searchResults);
  return (
    <Page>
      <Container>
        <Category type="list-searchresult" prefilledData={searchResults} description="" title={`Hakutulokset: ${search.searchQuery}`} />
      </Container>
    </Page>
  )
});

export default SearchResultsPage
